<div *ngIf="productDetails != undefined" class="enquire">
  <p class="heading">Contact {{storeName}}</p>
  <div id="enquireBdyOuter" class="enquireBdyOuter">

    <div id="enquireContent" class="enquireContent">

      <div id="enquireHeader" class="enquireHeader" fxLayout="row">
        <p fxFlex="16.66666667"></p>
        <p id="productDescHdr" class="productDescHdr" fxFlex="33.33333333">Product Description</p>
        <p id="QtyHdr" class="QtyHdr" fxFlex="25"> Qty</p>
        <p id="productPriceHdr" class="productPriceHdr" fxFlex="25"> Price </p>
      </div>
      <div id="enquireBody" class="enquireBody">
        <ul class="enquireItr">
          <li fxLayout="row" *ngFor="let list of productDetails">
            <a class="pro-img" fxFlex="16.66666667" [routerLink]="goToProductDetailPage(list?.productModel)" [queryParams]="getQueryParamsForProduct(list?.productModel)" style="position:relative">
              <img [src]="list.imageUrl != undefined?list.imageUrl:defaultPath" class="center-position" style="max-height:100%; max-width:100%"
                (error)="imgErrorHandler($event)">
            </a>
            <div class="pro-desc" fxFlex="33.33333333" fxLayout="column">
              <p class="title">{{list.productName}}</p>
              <p class="sub-title"></p>
              <p class="size-rec"></p>
            </div>
            <div class="quantity" fxFlex="25">
              <!-- <p fxShow.lt-sm fxHide.gt-xs>Qty : </p> -->
              <input value="1">
            </div>
            <div class="price" fxFlex="25">
              <!-- <p fxShow.lt-sm fxHide.gt-xs>Price : </p> -->
              <p>{{list.salePrice | currency:currType}}</p>
            </div>
            <div class="remove-enquiry">
              <span></span>
            </div>
          </li>
        </ul>

        <!-- <ul id="enquireItr" class="iterator enquireItr">
          <li class=" floatedLeft">
            <div id="enquireItrPnl" class="enquireItrPnl" data-title-id="275">

              <a href="details/bamboo-solar-calculator?t=275" id="prodImgLnk" class="prodImgLnk">
      
      
      <img id="prodImgEnquiry" class="prodImgEnquiry img-responsive center-position" _componenttype="8" src="http://192.200.1.204:8080/cdn//fetch/2/01/233/images/productImages/275/m/temp-a-LSNetX1486716122650.jpg" alt="Bamboo Solar Calculator" onerror="uploadDefaultProductImage($event)">
      </a>
              <div id="prodDescPnlEnquiry" class="prodDescPnlEnquiry col-xs-4 noPadding" _componenttype="12">

                <p id="productTitleEnquire" class="productTitleEnquire" _componenttype="10">
                  Bamboo Solar Calculator
                </p>
                <p id="subtitleLblEnquiry" class="subtitleLblEnquiry" _componenttype="10">

                </p>
                <p id="sizeLblEnquiry" class="hide" _componenttype="10">

                </p>
              </div>
              <div id="qtyPnlForEnquiry" class="qtyPnlForEnquiry col-xs-3 noPadding" _componenttype="12">

                <input value="1" type="text" id="productQtyEnquire" class="productQtyEnquire" _componenttype="17" data-contenttype="0">
              </div>
              <div id="pricePnlForEnquiry" class="pricePnlForEnquiry col-xs-3 noPadding" _componenttype="12">

                <span id="Span269" class="WebRupee">
      
      </span>
                <p id="priceLblEnquiry" class="priceLblEnquiry" _componenttype="10">
                  800.00
                </p>
              </div>
              <a id="enquiryItemRemoveLnk" class="enquiryItemRemoveLnk hide" _componenttype="11">
      
      
      <span id="Span262" class="glyphicon glyphicon-trash">
      
      </span>
      </a>
            </div>
          </li>


        </ul> -->
      </div>
    </div>
    <form enctype="multipart/form-data" [formGroup]="enquiryForm" (ngSubmit)="submitEnquiry(errorFlag)">
      <div id="enquireMsgPnl" class="enquireMsgPnl">

        <p id="enquireMsgLbl" class="enquireMsgLbl boldLbl">
          Message (min. 50 characters)
        </p>
        <textarea [value]="message" id="enquireMsgTa" class="enquireMsgTa " rows="1" autoresize="false" formControlName="message"
          minLength="50"></textarea>
      </div>
      <div class="attch-file" style="position:relative;">
        <p #fileName style="display:inline-block;color:#000;pointer-events:none;"></p><span #changeMenu class="attchment"
          style="font-weight:bold"> + Add Attachment</span>
        <input type="file" class="file-upload" style="opacity:0;position:absolute; top:0;left:0;width:50%;" (change)="onFileChange($event, fileName, changeMenu)">
      </div>
      <div id="btnPnlForEnquiry" class="btnPnlForEnquiry" fxLayoutAlign="end start" fxFlexAlign="start">

        <p id="errorLblForEnquiry" class="errorLblForEnquiry hide redLbl">
        </p>
        <div id="enquireNamePnl" class="enquireNamePnl" fxFlex="16.66666667">

          <input [value]="enquiryName" type="text" id="enquiryNameTf" class="enquiryNameTf" data-contenttype="0"
            placeholder="Enquiry Name" formControlName="name">
        </div>
        <p class="error-label" #errorFlag></p>
        <div id="sendEnquiryBtnPnl" class="sendEnquiryBtnPnl">

          <button *ngIf="!updateEnquiry" id="sendEnquiry" class="sendEnquiry" data-from-cart="3" data-cart-id="">
            Send Enquiry Now

          </button>
          <button *ngIf="updateEnquiry" id="sendEnquiry" class="sendEnquiry" data-from-cart="3" data-cart-id="">
            Update Enquiry
          </button>
        </div>
      </div>
    </form>

  </div>
</div>