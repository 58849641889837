<div class="pnlOrderQueryOuter">
  <div class="innerBdy">
    <div class="heading-lbl">
      <p class="heading">Order Query</p>
      <span class="close-pop-up fa fa-lg fa-times-circle-o" (click)="closeDialog()"></span>
    </div>
    <div class="formBdy">
      <form  [formGroup]="orderQueryForm" (ngSubmit)="orderQuerySubmit($event)">
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryOrderId orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>OrderId</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{dataList?.orderDisplayId}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryName orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>Name</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{currUserName}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryEmail orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>Email</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{currUser_email}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryPhoneNum orderQryOuterPnls">
          <div fxLayout="start center" class="label">
            <p>Phone</p>
          </div>
          <div fxLayout="start center" class="value">
            <p>{{currUser_mobile}}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryComboOuter">
          <div fxLayout="start center" class="label">
            <p>Query</p>
          </div>
          <div fxLayout="start center" class="value">
            <select formControlName="queryName">
              <option *ngFor="let item of queryNames" [value]="item.value">{{item.label}}</option>
            </select>

            <!-- <ls-combo ngModel [multiple]=false  [options]="queryNames" formControlName="queryName" placeholder="Select Query"></ls-combo>                 -->
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryComment">
          <div fxLayout="start center" class="label">
            <p>Comment</p>
          </div>
          <div fxLayout="start center" class="value">
            <textarea class="txtFieldCls" formControlName="desc"></textarea>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="pnlOrderQueryButton orderQryOuterPnls">
          <div fxLayoutAlign="center center" class="sendbtn">
            <button type="submit" [disabled]="!orderQueryForm.valid">Send</button>
          </div>
          <div fxLayoutAlign="center center" class="cancelBtn">
            <button type="reset" (click)="closeDialog()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>