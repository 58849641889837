import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductDetailsService } from '../../../services/product-details.service';
import { LsDialogService } from '../../../LSNG/components/ls-dialog/ls-dialog.service';
import { ProductModel } from '../../../common/models/productModel';
import { UserModel } from '../../../common/models/user-model';
import { EnquiryModel } from '../../../common/models/enquiry-model';
import { EnquireService } from '../../../services/enquire.service';
import { LoginComponent } from '../../components/login/login.component';
import { OkayMessageComponent } from '../../commonComponent/okay-message/okay-message.component';
import { CartService } from '../../../services/cart.service';
import { CartModel } from '../../../common/models/cartModel';
import { CartProductModel } from '../../../common/models/cartProductModel';
import { CartCountService } from '../../../services/cart-count.service';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { TemplateConstants } from '../../commonComponent/template-constants';
import * as FormData from 'form-data';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss'],
  providers: [CartService]

})
export class EnquiryComponent implements OnInit {
  titleIdList: Array<number> = new Array<number>()
  productDetails: Array<ProductModel> = new Array<ProductModel>();
  productList: Array<CartProductModel>;
  enquireDetails: EnquiryModel
  productHref: string
  productTitle: string
  salePrice
  currType: string = "INR"
  selectedFile: File;
  formData = new FormData();
  enquiryForm: FormGroup
  userState: UserModel;
  message: string = "";
  enquiryName: string = ""
  currUserEmail
  cart: CartModel = new CartModel()
  cartProducts: Array<CartProductModel> = new Array()
  updateEnquiry: boolean
  defaultPath = TemplateConstants.defaultImgPath;
  priceRoundOffInteger: string = '1.2';
  storeName = Constants.WAR_NAME;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private productDetailsService: ProductDetailsService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private enquiryService: EnquireService,
    private loginDialog: LsDialogService<LoginComponent>,
    private dialogService: LsDialogService<OkayMessageComponent>,
    private cartService: CartService,
    private cartCountService: CartCountService
  ) { }

  ngOnInit() {
    this.enquiryForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      message: ["", [Validators.required, Validators.minLength]]
    })
    if (this.activatedRoute.snapshot.queryParamMap.get("c") != null) {

      let cartId = this.activatedRoute.snapshot.queryParamMap.get("c").toString();
      if (cartId != "0") {
        this.updateEnquiry = true;
        this.enquiryService.getParticularCart(cartId, resp => {
          this.enquireDetails = resp.data[0];
          this.enquireDetails.cartProductModels.forEach(model => {
            this.productDetails.push(model.productModel);
          })
          this.productList = this.enquireDetails.cartProductModels;
          if (this.enquireDetails.enquiryMessage != null) {
            this.message = this.enquireDetails.enquiryMessage;
          } else {
            this.message = "";
          }
          if (this.enquireDetails.enquiryName != null) {
            this.enquiryName = this.enquireDetails.enquiryName;
          } else {
            this.enquiryName = "";
          }
        })
      }
    } else {
      if (this.activatedRoute.snapshot.queryParamMap.get("t") != null) {
        this.titleIdList.push(Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get("t").toString()));
        this.getEnquiryProductInfo();
      } else {
        if (isPlatformBrowser(this.platformId)) {
          if (localStorage.getItem('currentUser') != null) {
            this.currUserEmail = JSON.parse(localStorage.getItem('currentUser')).email;
          }
        }
        this.cartCountService.cart.subscribe((cart) => {
          if (cart) {
            this.cart = cart;
            if (this.cart.cartProductModels && this.cart.cartProductModels.length > 0) {
              this.cartProducts = this.cart.cartProductModels;
              this.cartProducts.forEach(model => {
                this.titleIdList.push(model.titleId);
                this.getEnquiryProductInfo();
              })
            }
          } else {
            return;
          }
        });
      }
    }
    if (Constants.ONLINE_STORE_SETTING_MODEL != undefined) {
      if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO != undefined) {
        if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 1) {
          this.priceRoundOffInteger = '1.0'
        } else if (Constants.ONLINE_STORE_SETTING_MODEL.generalSettingsDTO.priceRoundOffInteger == 0) {
          this.priceRoundOffInteger = '1.2'
        }
      }
    }
  }

  getEnquiryProductInfo() {
    let offerBookCall = false;
    this.productDetailsService.getProductDetails(false, this.titleIdList, 1, offerBookCall, (data) => {
      if (data != undefined) {
        this.productDetails = data.data;
      }
    })

  }

  onFileChange(event, fileName, changeMenu) {
    this.selectedFile = event.target.files[0];
    if (event.target.files.length > 0) {
      let fileToUpload = event.target.files[0];
      this.formData = new FormData();
      this.formData.append("file[]", fileToUpload, fileToUpload.name);
      fileName.innerHTML = this.selectedFile.name;
      if (!changeMenu.className.includes('changeLnk')) {
        changeMenu.innerHTML = "Change";
        changeMenu.className = "attchment changeLnk";
      }
    }
  }

  submitEnquiry(errorFlag) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.enquiryForm.value.message.length == 0) {
        errorFlag.innerHTML = "Enquiry message is mandatory";
        errorFlag.className = "error-label show";
      } else if (this.enquiryForm.value.message.length < 50) {
        errorFlag.innerHTML = "Enquiry message should be 50-500 characters long";
        errorFlag.className = "error-label show";
      } else if (this.enquiryForm.value.name.length == 0) {
        errorFlag.innerHTML = "Enquiry Name is mandatory !";
        errorFlag.className = "error-label show";
      }
      else if (localStorage.getItem('currentUser') != null) {
        errorFlag.className = "error-label hide";
        this.userState = JSON.parse(localStorage.getItem('currentUser'));
        // this.userName = this.userState.userName;
        this.sendEnquiry();
      } else {
        this.loginDialog.open(LoginComponent, { panelClass: 'loginPopUp' }, null).subscribe(response => {
          if (localStorage.getItem('currentUser') != null) {
            this.userState = JSON.parse(localStorage.getItem('currentUser'));
            // this.userName = this.userState.userName;
            this.sendEnquiry();
          }
        })
      }
    }



  }

  sendEnquiry() {
    let enquiryModel: EnquiryModel = new EnquiryModel();
    let fileName: Array<string> = new Array<string>();
    if (this.selectedFile != undefined) {
      fileName.push(this.selectedFile.name);
      enquiryModel.enquiryAttachment = this.selectedFile.name;
    }
    enquiryModel.enquiryMessage = this.enquiryForm.value.message;
    enquiryModel.enquiryName = this.enquiryForm.value.name;
    let cartProductModels: Array<CartProductModel> = new Array();

    this.productDetails.forEach(model => {
      let cartModel = new CartProductModel();
      cartModel.productModel = model;
      cartModel.titleId = Number.parseInt(model.titleId)
      cartModel.variantId = model.id;
      if (this.activatedRoute.snapshot.queryParamMap.get("t") == null && this.activatedRoute.snapshot.queryParamMap.get('c') == null) {
        enquiryModel.fromCart = true;
        // cartModel.isFromCart = true;
      }
      cartProductModels.push(cartModel);
    })
    let updateEnq = false;
    enquiryModel.cartProductModels = cartProductModels;
    if (this.activatedRoute.snapshot.queryParamMap.get('c') != null) {
      updateEnq = true;
      enquiryModel.cartId = Number.parseInt(this.activatedRoute.snapshot.queryParamMap.get('c').toString());
    }
    if (this.selectedFile != undefined) {
      this.formData.append('file', this.selectedFile);
    }
    this.formData.append('data', JSON.stringify(enquiryModel));
    this.enquiryService.sendEnquiry(this.formData, enquiryModel, updateEnq, resp => {
      if (!resp.error) {
        this.dialogService.open(OkayMessageComponent, {}, resp.msgList[0]).subscribe(response => {
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = "my-dashboard/enquiries";
          }
        })
      }
    })
  }

  imgErrorHandler(event){
    event.target.onerror = null;
    event.target.src = this.defaultPath;
  }

  goToProductDetailPage(product) {
    let url: string;
    if (product.productURLText) {
      url = product.productURLText != undefined ? product.productURLText : '';
    }
    let target = '/details/' + url;
    return target;
  }

  getQueryParamsForProduct(product) {
    let queryParams;
    if (product.titleId) {
      queryParams = { 't': product.titleId };
    }
    return queryParams;
  }

}
