<header *ngIf="!checkOutVisibility" class="defaultWidth">
  <div fxLayout="column" class="header-column desktop-layout">
    <div class="header-top">
      <div id="logo">
        <a [href]="''" style="height:100%; width:100%; position:relative;" *ngIf="companyDetails">
          <img [src]="companyDetails?.storeLogo ?companyDetails?.storeLogo:defaultPath "
            onError="this.src = 'assets/images/defaultLogo.png'" title="{{companyDetails?.storeName}}"
            alt="{{companyDetails?.storeName}}">
        </a>
      </div>
      <div class="heading-tag col-xs-3">
        <!-- <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails?.storeTagLine}}</p> -->
        <div *ngIf="currencyList != undefined" fxLayout="column" class="currency-combo">
          <div class="selected-div" (click)="showCurrencyList(currencyOption)">
            <span [id]="currencyList[selectedCurrencyIndex]?.currencyCode" class="currency-span"></span>
            <a class="currency-value"> {{currencyList[selectedCurrencyIndex]?.currencyCode}}</a>
          </div>
          <ul class="currency-option" #currencyOption>
            <li *ngFor="let model of currencyList; let idx = index" class="currency-li" (click)="updateCurrency( idx,currencyOption )">
              <span [id]="model.currencyCode" class="currency-span"></span>
              <a class="currency-value">{{model?.currencyCode}}</a>
            </li>
          </ul>
        </div>
        <!-- <div class="user-links">
          To Be Hidden when user not logged in 
          <div *ngIf="loggedIn" id="pnlMyAccount" class="pnlMyAccount">
            <a id="lblMyAccnt" class="lblMyAccnt" routerLink="my-dashboard/profile"
              routerLinkActive="active"> Dashboard </a>
            <a id="lnk_wishlist" routerLink="my-dashboard/wishlist" *ngIf="allowWishlist">Wishlist ({{wishlistCount}}) </a>
            <a id="lnk_login" class="signInDtl" (click)="logOut()">Sign Out</a>
          </div>
          <p *ngIf="!loggedIn" class="track-order" (click)="showTrackPopOver()">Track Order</p>
          <p *ngIf="!loggedIn" class="login" (click)="onOpen()">Sign In</p>
        </div> -->
        <div class="right-pnl">
          <!-- <div class="cart" (click)="openCart()">
            <span class="cart-img"></span>
            <p class="cart-count">{{cartCount}}</p>
          </div> -->
          <div id="trackDtls" *ngIf="!loggedIn" >
            <p class="track-order" (click)="showTrackPopOver()">Track Order</p>
          </div>
          <div *ngIf="loggedIn" id="pnlLoginDtls" class="displayLoginDtls">
            <p id="lblUserName" class="lblUserName" [innerHTML]="userState.userName !=null ? 'Hello '+ userState.userName + '!!' : 'Hello '+userState.fName + '!!'"></p>
            <span class="fa fa-caret-down"></span>
            <div id="dashOptionPnl" tabindex="1">
              <a routerLink="my-dashboard/profile" routerLinkActive="active">My Profile</a>
              <a routerLink="my-dashboard/orders" routerLinkActive="active">My Orders</a>
              <a routerLink="my-dashboard/transactions" routerLinkActive="active">Transaction Log</a>
              <a routerLink="my-dashboard/change-password" routerLinkActive="active">Change Password</a>
              <a routerLink="my-dashboard/wishlist" routerLinkActive="active">My Wishlist</a>
              <!-- <a id="lnk_login" class="signInDtl" (click)="logOut()">Sign Out</a> -->
            </div>
          </div>
          <div *ngIf="!loggedIn" id="pnlLoginDtls">
            <!-- <p id="lblHi" class="lblUserName">Welcome</p> -->
            <p id="lblUserName" class="lblUserName" [innerHTML]="'Welcome Guest'"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <!-- <div class="category">
        <p class="categories" fxShow.gt-xs fxHide.lt-sm>All Categories</p>
        <div class="fetch-categories">
          <ul *ngIf="categoryList != undefined">
            <li *ngFor="let category of categoryList">
              <div id="itrCat-0">
                <a attr.categoryid={{category?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{category?.categoryURL}}">
                  <h1>{{category?.categoryName}}</h1>
                </a>
                <ul *ngIf="category?.categoryModels != undefined" class="itrCat">
                  <li *ngFor="let subCat of category?.categoryModels">
                    <div id="itrCat-1">
                      <a attr.categoryid={{subCat?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{subCat?.categoryURL}}"
                        style="position:relative">
                        {{subCat?.categoryName}}
                        <span *ngIf="subCat?.categoryModels != undefined" class="arrow-upleft fa fa-lg fa-angle-right"
                          style="position: absolute;right: 9px;font-weight: bold;"></span>
                      </a>
                      <ul *ngIf="subCat?.categoryModels != undefined" class="itrCat secCat">
                        <li *ngFor="let secLevel of subCat?.categoryModels" class="secLevel">
                          <div id="itrCat-2">
                            <a attr.categoryid={{secLevel?.categoryId}} class="lnkCatName" id="lnkCatName" routerLink="collections/{{secLevel?.categoryURL}}">
                              {{secLevel?.categoryName}}
                            </a>
                            <ul *ngIf="secLevel?.categoryModels != undefined" class="itrCat thirdCat">
                              <li *ngFor="let thirdLevel of secLevel?.categoryModels" class="thirdLevel">
                                <div id="itrCat-3">
                                  <a attr.categoryid={{thirdLevel?.categoryId}} class="lnkCatName" id="lnkCatName"
                                    routerLink="collections/{{thirdLevel?.categoryURL}}">
                                    {{thirdLevel?.categoryName}}
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div> -->
      <div class="search-panel col-xs-10">
        <app-search></app-search>
      </div>
      <div class="pnlCartOuter col-xs-2" [ngClass]="{'hide': !allowCart}">
        <div class="cart" (click)="openCart()">
          <span class="fa fa-shopping-cart"></span>
          <div class="cartCntPnl">
            <p class="cart-count">{{cartCount}}</p>
          </div>
        </div>
        <div class="pnlSignInHdr">
          <p class="login" *ngIf="!loggedIn" class="login" (click)="onOpen()">Sign In</p>
          <p class="login" *ngIf="loggedIn" class="login" (click)="logOut()">Sign Out</p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div fxShow.lt-sm fxHide.gt-xs fxLayout="column" style="background-color:#f5f5f5;" class="mobile-layout">
    <div class="tagline" fxLayout="column" fxFlex="100">
      <p fxLayout="column" fxFlex="100" class="tagline">{{companyDetails?.storeTagLine}}</p>
    </div>
    <div fxLayout="row" style="border-top:5px solid #04582d; height:70px;">
      <div fxFlex="14" class="nav-panel" (click)="showCategories()"><span class="nav-bar"> </span></div>
      <div fxFlex="28" style="height:100%" class="img-logo" style="position:relative" (click)="navigateToHome()">
        <img src="{{companyDetails?.storeLogo}}" onerror="uploadDefaultLogoImage($event)" title="{{companyDetails?.storeName}}"
          alt="{{companyDetails.storeName}}" style="max-height:100%; max-width:100%;">
      </div>
      <div class="user-info" fxFlex="57" fxLayout="row" fxLayoutAlign="end end">
        <div class="track-panel" fxFlex="20" (click)="showTrackPopOver()"><span class="track-mob"></span></div>
        <div class="cart-panel" fxFlex="20" (click)="openCart()"><span class="cart-mob"></span><span class="cart-count">{{cartCount}}</span></div>
      </div>
    </div>
    <div fxLayout="row" class="search-panel">
      <app-search></app-search>
    </div>
  </div> -->
</header>